
import {get,put,del,post} from "@/service/base";
//获取病历模板列表
// export function getmoBanList(is_sh_case,is_sh_pic){
//     return get('/api/admin/caseTemplates',{is_sh_case,is_sh_pic})
// }
export function getmoBanList(data){
    return get('/api/admin/caseTemplates',data)
}
//病例列表 分页
export function getCaseList(page_no,page_size,template_id,no_passed,audit_status,start_time,end_time){
    return get('/api/admin/cases',{page_no,page_size,template_id,no_passed,audit_status,start_time,end_time})
}
//图片打码列表
export function getPicturesCode(page_no,page_size,template_id,imageAuditStatus,start_time,end_time){
    return get('/api/admin/cases',{page_no,page_size,template_id,imageAuditStatus,start_time,end_time})
}
//获取数据统计
export function getNumberList(template_id,type,start_time,end_time){
    return get('/api/admin/case/statisticalTotalCount',{template_id,type,start_time,end_time})
}
// //分页
// export function getPageList(page_no,page_size,template_id,no_passed){
//     return get('/api/admin/cases',{page_no,page_size,template_id,no_passed})
// }
//选择状态
export function getZhuangList(audit_status,template_id){
    return get('/api/admin/cases',{audit_status,template_id})
}
//获取单个病例详情
export function getCaseDetail(id){
    return get('/api/admin/case/' + id)
}

//病例详情修改性别
export function putCaseDetail(id,data){
    return put('/api/admin/patientByCaseId/' + id,data)
}

//删除列表
export function delCaseList(id){
    return del('/api/admin/case/' + id)
}
//-------------------质控-------------------
//获取列表
export function getZkList(grade,page_size,page_no,name,city,tel,hospitalName){
    return get('/api/admin/zk/members',{grade,page_size,page_no,name,city,tel,hospitalName})
}
//获取医院名称列表
export function getHospital(province,city){
    return get('/api/admin/hospitals/'+province+'/'+city)
}
//添加用户
export function postAdmin(data){
    return post('/api/admin/zk/member',data)
}
//修改用户
export function putAdmin(id,data){
    return put('/api/admin/zk/member/'+id,data)
}
//删除
export function delAdmin(memberIds){
    return del('/api/admin/zk/member', {memberIds:memberIds})
}
//重置密码
export function putPassword(data){
    return put('/api/admin/zk/member/resetPassword', data)
}
/**
 * 修改病例  通过
 * @param id
 * @param data  病例内容
 * @param isAudit   审批
 * @returns {Promise<* | void>}
 */
export function putCaseGo(id,data,templeteIdY){
    return put('/api/admin/case/' + id + '/' + templeteIdY,data)
}
//检查住院号是否重复
export function TemplateIdRepeatHospitalNumber(id,hospitalNumber){
    return get(`/api/admin/caseTemplateIdRepeatHospitalNumber/${id}/${hospitalNumber}`)
}
//病例识别
export function getCaseMessage(id){
    return get('/api/admin/case/'+id)
}
//病例-----------------------------------------主动脉夹层
/**
 * 修改性别
 * @param id
 * @param data
 * @returns {*}
 */
export function putSex(id,data){
    return put('/api/admin/patientByCaseId/'+id,data)
}
//病例识别
export function getCaseMessage_course(id){
    return get('/api/admin/caseTemplate/'+id)
}